<template>
  <vue-tel-input v-model="nationalNumber" @input="onInput" :defaultCountry="defaultCountry" :preferredCountries="['NO', 'SE', 'DK']"
  :class="{ validfield: isValid, invalidfield: isError }" v-if="loaded"></vue-tel-input>
</template>

<script>
import { VueTelInput } from 'vue-tel-input'
import { parsePhoneNumberFromString } from 'libphonenumber-js'

export default {
  props: {
    value: String,
  },
  emits: ['input'],
  components: {
    VueTelInput,
  },
  data() {
    return {
      isValid: false,
      isError: false,
      nationalNumber: '',
      defaultCountry: 'NO',
      loaded: false,
    }
  },
  mounted() {
    this.initializePhoneNumber()
  },
  watch: {
    value() {
      this.initializePhoneNumber()
    },
  },
  methods: {
    onInput(number, isValid) {
      this.isValid = isValid.valid
      this.isError = !isValid
      this.$emit('input', isValid.number)
    },
    initializePhoneNumber() {
      const phoneNumber = this.value
      if (!phoneNumber) {
        this.loaded = true
        return
      }
      const parsedNumber = parsePhoneNumberFromString(phoneNumber)
      if (parsedNumber) {
        this.nationalNumber = parsedNumber.nationalNumber
        this.defaultCountry = parsedNumber.country
      } else {
        this.nationalNumber = phoneNumber
        this.defaultCountry = 'NO'
      }
      this.loaded = true
    },
  },
}
</script>

<style src="vue-tel-input/dist/vue-tel-input.css">
</style>

<style>
.vue-tel-input {
  height: 32px;
  border: 1px solid #e4e9f0!important;
}
.validfield {
    border-color: green!important
  }
  .invalidfield {
    border-color: red!important
  }
</style>
